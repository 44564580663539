
import PromiseBunny from "../images/bunnypromise.png"
import Mission from "../images/mission.png"
function LandingPage() {
  
    return (
      <div class="w-full bg-green-400">
          <div class="w-full h-8"></div>
        <div class="w-full text-xl flex gap-3 justify-center flex-row">
            <div  class="text-3xl tricks_title">tri-x</div>    
        </div>
        <div class="w-full text-center p-4 text-3xl text-green-100">want to learn sorcery for product creators?</div>
        <div class="w-full text-center px-4 pb-4 text-3xl font-bold">only 2$</div>
        <div class="w-full  text-base font-light mb-4"><div class="w-11/12 mx-auto"><img src={Mission}/></div></div>
        <div class="w-full text-xl font-bold"><div class="w-10/12 text-center mx-auto">GET TO IMPROVING EXISTING PRODUCTS, TODAY</div></div>
        <div class="w-full p-4"><img class="px-16 mx-auto" alt="bunny promise" src={PromiseBunny}></img></div>
        <div class="w-full my-4">
            <div class="mx-auto border-2 font-bold rounded-lg text-lg border-green-100 bg-black w-10/12 p-6">
                <p class="text-white">Signup to Dashboard</p>
                <p class="text-white">(it's free)</p>
                <p class="text-green-100">BELOW IS THE CONTENT YOU GET</p>
            </div>
        </div>
        <div>
            LIST OF FRAMEWORKS
        </div>
        <div>
            Organisation
        </div>
        <div className="h-4"></div>
        <div className="h-4"></div>
        <div className="w-full text-center"> &#169; 123 VENTURES</div>

        <div className="h-4"></div>
        <div className="h-4"></div>
      </div>
    );
  }
  
  export default LandingPage;
  