import logo from './logo.svg';
import './App.css';
import Page from './screens/page';
import LandingPage from './screens/landingPage'
import { useEffect,useState } from 'react';


function getWindowDimensions() { 

  if (typeof window !== "undefined") {
    const { innerWidth, innerHeight } = window;

  return {
    innerWidth,
    innerHeight
  };
  }

  return {innerWidth:0,innerHeight:0}
  
}

function App() {


  const [l,setL] = useState(()=>getWindowDimensions())

  useEffect(()=>{
    setL(getWindowDimensions());
  })

  return (
    <div>
    {l.innerWidth>600?
    <div className="bg-green-400 flex h-screen">
      <div className="m-auto">
       <h3 className=" tricks_title text-2xl">Tri-x</h3>
      <h3>Please use a mobile to access the course</h3>  
      </div>
    </div>
    :
    <div class="bg-black">
      <LandingPage/>
    </div>}
    </div>
  );
}

export default App;
